<template>
  <div class="wrapper">
    <div class="header-img">
      <input type="checkbox" id="input" hidden />
        <div class="box" id="jjjj">
          <div class="row justify-content-center">
            <label for="input" id="im">
              <div class="container acceso">
                <div class="row justify-content-center">
                  <div class="col">
                    <form>
                    <button type="button" @click="returnHome()" class="btn-close" aria-label="Close">X</button>
                    <h1 class="text text-center mt-5 mb-4">Acceso Taxistas<br>
                      
                    </h1>
                    <div class="form-group mt-3">
                      <label>Por favor, introduce tu licencia</label>
                      <input type="text" v-model="license" class="form-control form-control-lg" />
                      </div>
                    <div class="form-group mt-3 mb-4">
                      <label>Por favor, introduce tu pin</label>
                      <input type="text" v-model="pin" class="form-control form-control-lg" />
                    </div>
                    <p v-if="isInvalid === true"  class="alert alert-warning">Parece que existe un error en los datos introducidos, por favor, inténtalo de nuevo.</p>
                    <!--MOCK PARA PODER ACCEDER DE FORMA TEMPORAL-->
                      <button type="submit" class="btn btn-dark btn-lg btn-block mt-5 mb-5" @click="mock()">Enviar</button>
                      <!--
                    <button type="submit" @click="login()" class="btn btn-dark btn-lg btn-block mb-5">Enviar</button>
                    -->
                    </form>
                  </div>
                </div>
              </div>
            </label>
          </div>
          <div class="container-fluid login_taxistas_footer">
          <div class="row justify-content-center">
            <div class="col">
              <figure>
                <p class="col justify-content-left marca">
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/cluf.html">Contrato de Licencia de Usuario Final</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/politicaprivacidad.html">Política de Privacidad</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/copyrightylicencia.html">Copyright y Licencia de software</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/licenciasdeterceros.html">Licencias de terceros</a>
                  <a class="external_link" target="_blank" href="http://www.2kbo.com/requerimientostecnicos.html">Requerimientos técnicos</a>
                </p>
                <p class="marca_kbo">© KBO 2021. Marca KBO. Versión 1.0</p>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swa1 from 'sweetalert2';
//import { login } from '/home/hab8/Documents/KBO/2kbo-publicwebsite/api/utils.js'

export default {
  name: 'LoginTaxistasView',
  data() {
    return {
      license: '',
      pin: '',
      isInvalid: false,
    }
  },
  methods: {
    mock() {
        if(this.license === '9999' && this.pin === '4567' ) {
        this.$router.push('/panel-taxistas')
        } 
        if(this.license !== '9999' && this.pin !== '4567' ) {
          this.isInvalid = true
          this.license = '',
          this.pin = ''//Asi vacia los campos una vez enviada la informacion
        }
      },
    returnHome() {
        this.$router.push('/')
      },
    async login(license, pin) { 
      const ENDPOINT = 'http://localhost:3000'
   try {
         
         axios.post(`${ENDPOINT}/taxistas/login`, {
          license: this.license,
          pin: this.pin
        })
        .then((results) => {
           if(this.license === '' || this.pin === '') {
          this.isInvalid = true
          }
          
          //SI EMAIL O PASSWORD SON INVALIDOS ISINVALID = TRUE   
          if(!this.license && !this.pin) {
              this.license = '',
              this.pin = ''//Asi vacia los campos una vez enviada la informacion para crear nuevo cliente
              return this.isInvalid = true
          }
          else {
            if(results) {
            this.$emit('logindone')
            this.$router.push('/panel-taxistas')
            }
          }
        })
   } catch(response) {
     let error = 'Parece que existe un error en los datos introducidos, por favor, inténtalo de nuevo.'
        console.log(error);
        throw alert(error)
        }
    },  
    async loginTaxistas() {
      console.log(this.license);
      console.log(this.pin);
      try {
        //SI LICENSE O PIN ESTAN VACIOS SALDRA VENTANA DE ERROR, CAMPOS REQUERIDOS
        if(this.license === '' || this.pin === '') {
          this.isInvalid = true
          }
          
          //SI EMAIL O PASSWORD SON INVALIDOS ISINVALID = TRUE   
          if(this.login(!this.license && !this.pin)) {
              this.license = '',
              this.pin = ''//Asi vacia los campos una vez enviada la informacion para crear nuevo cliente
              return this.isInvalid = true
              
                  
          //SI TODO ES CORRECTO EMITIMOS EL EVENTO LOGINDONE QUE SERA ESCUCHADO POR EL PADRE (APP) Y REDIRECCIONAMOS
          } else {
            this.login(this.license, this.pin)
                .then (() => {
                    this.$emit('logindone')
                    Swa1.fire({
                      title: 'Acceso correcto',
                      icon: 'success',
                      confirmButtonText: 'Ok',
                      background: 'black',
                      confirmButtonColor: 'transparent',
                      showCancelButton: false,
                          willClose: () => {
                          this.isInvalid = false
                          this.$router.push('/panel-taxistas')
                          }
                    })
                })
          }
      }    
            catch (error) {
                console.log(error);
                   
                Swa1.fire({
                  title: 'Error, por favor, revise los parámetros introducidos',
                  icon: 'error',
                  confirmButtonText: 'Volver',
                  background: 'black',
                  confirmButtonColor: 'transparent',
                  showCancelButton: false,
                      willClose: () => {
                      this.isInvalid = true
                      }
                })
                this.license = '',
                this.pin = ''//Asi vacia los campos una vez enviada la informacion para crear nuevo cliente
              }
    }
      /**
      //FUNCION QUE COMPRUEBA QUE TODOS LOS CAMPOS REQUERIDOS EXISTEN    
        validatingDataRegister() {
                if(
                this.license === ''||
                this.pin === ''
               ) { //comprobamos que no hay campos vacios
                    this.errorMsg = true
                    this.createUser = false;
                    Swa1.fire({
                    title: 'Por favor, rellene todos los campos',
                    icon: 'error',
                    confirmButtonText: 'Volver',
                    background: 'black',
                    confirmButtonColor: 'transparent',
                    showCancelButton: false,
                    })
                
                }  else {
                    this.errorMsg = false
                    this.createUser = true;
                    this.addNewUser()
                    }
        },        
            
        addNewUser() {
            if(this.createUser === true) {
                axios.post('http://localhost:3000/taxistas/login', {
                license: this.license,
                pin: this.pin
                })
                .then((results) => {
                  console.log(results);
                  if (results.data === 204) {
                  this.response = true
                  console.log(results);
                  }
                  if (results.data === 400)
                  this.response = false
                })
                .catch((error) => {
                    console.log(error)
                })
                this.createUser = false;
                this.license = '',
                this.pin = ''//Asi vacia los campos una vez enviada la informacion para crear nuevo cliente
            } else {
                console.log('Yo no deberia estar aqui');
            }
        },
         async goToDashboard() {
          if (this.response === true) {
            //await this.$router.push('/panel-taxistas')
            console.log(this.response);
          } 
          else if(this.response === false) {
            await Swa1.fire({
                    title: 'Error, por favor, revise los parámetros introducidos',
                    icon: 'error',
                    confirmButtonText: 'Volver',
                    background: 'black',
                    confirmButtonColor: 'transparent',
                    showCancelButton: false,
                        onClose: () => {
                        location.assign("http://localhost:8080/#/registro-taxistas")
                        }
                    
                    }) 
            console.log(this.response);
          }
          */
  } 
}
</script>

<style lang="scss" src="/home/hab8/Documents/KBO/2kbo-publicwebsite/src/scss/estilos.scss" scoped></style>